$breakpoints: (
  "xsmall": (
    max-width: 360px
  ),
  "small": (
    max-width: 700px
  ),
  "medium": (
    max-width: 768px
  ),
  "xmedium": (
    max-width: 900px
  ),
  "large": (
    max-width: 1200px
  )
) !default;

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: ($lineHeight * $lineCount) * 0.95;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

@mixin multiline-clamp-ellipsis($lineHeight: 1rem, $lineCount: 1) {
  max-width: 100%;
  height: ($lineHeight * $lineCount);
  line-height: $lineHeight;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

::ng-deep .mat-flat-button {
  outline: none !important;
}
