#content-wrapper {
  width: 100%;
  height: 100%;
}

footer {
  height: 35px;
  padding-top: 20px;
}

footer p {
  font-weight: 300;
  font-family: "Source Sans Pro", calibri, Arial, sans-serif;
  margin: 0;
  text-align: center;
  font-size: 14px;
}
footer a {
  margin: 0;
  color: white;
  text-decoration: underline;
}
footer a:hover {
  text-decoration: none;
}
footer p.built {
  font-size: 12px;
}
