@use "@angular/material" as mat;
@import "credentials";
@import "~swiper/dist/css/swiper.min.css";
//angular-notifier #yarn add angular-notifier
@import "~angular-notifier/styles";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
@import "./../styles/variables";
@import "../styles/mixins";

$siila-blue-color: #00334d;
$siila-orange-color: #ff9900;
$break-large: 500px;
$white: #fff;
$siila-green: #20c634;
$siila-red: #d51c13;
$siila-blue-dark: #1976d2;
$link-blue: #5c89a7;
$menu-link-color: #212121;
$siila-gray-color: #616161;
$siila-index-value: #331f00;
$tooltip-background-color: #746c6c;
$tooltip-text-color: #ffffff;
$tooltip-font-size: 16px;

@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$brand-darkblue: (
  100: #a8bac3,
  200: #7e98a5,
  300: #547688,
  400: #2a556a,
  500: #00334d,
  600: #00293e,
  700: #001f2e,
  800: #00141f,
  900: #000f17,
  A100: #88a7ff,
  A200: #4b79ff,
  A400: #3266ff,
  A700: #225bff,
  contrast: (
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$brand-orange: (
  100: #ffebcc,
  200: #ffd699,
  300: #ffc266,
  400: #ffad33,
  500: #ff9900,
  600: #cc7a00,
  700: #995c00,
  800: #ff7c00,
  900: #331f00,
  A100: #ffffff,
  A200: #ffe1da,
  A400: #ffcdc1,
  A700: #ffc0b1,
  contrast: (
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #331f00,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$light-blue: (
  100: #cedce5,
  200: #aec4d3,
  300: #8dacc1,
  400: #749bb4,
  500: #5c89a7,
  600: #54819f,
  700: #4a7696,
  800: #416c8c,
  900: #30597c,
  A100: #8bdcff,
  A200: #4ec9ff,
  A400: #34c1ff,
  A700: #25bdff,
  contrast: (
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$my-primary: mat.define-palette($brand-darkblue, 500);
$my-accent: mat.define-palette($brand-orange, 500);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent
    )
  )
);

$button-level: mat.define-typography-level(
  $font-weight: 600,
  $font-size: 14px,
  $line-height: 36px
);

$custom-typography: mat.define-typography-config(
  $button: $button-level
);

@include mat.core($custom-typography);
@include mat.button-typography($custom-typography);
@include mat.all-component-themes($my-theme);

//FOR NGX-GALLERY
.mat-sidenav-container,
.mat-sidenav-content,
.mat-tab-body-content {
  transform: none !important;
}

//buttons

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: $siila-blue-color;
}

//Application wide sass styles
html,
body {
  height: 100%;
  margin: 0;
  padding-top: 0;
}

body {
  display: flex;
  flex-direction: column;
  // background: #222837 url('../assets/img/background.png') fixed top center;
  background-size: cover;
  color: white;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*:not(.material-icons) {
  /* font-weight: 300; */
  font-family: "Roboto", sans-serif;
  //font-family: 'Source Sans Pro', calibri, Arial, sans-serif !important;
}

.siila-orange-bg {
  background: #ff9900;
  color: white;
}

.siila-blue-bg {
  background: $siila-blue-color;
}

.siila-green-bg {
  color: $siila-green;
}

.siila-red-bg {
  color: $siila-red;
}

.siila-orange {
  color: #ff9900;
}

.grocs-sub-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1px;

  li {
    margin-bottom: 15px;
  }
}

.grocs-sub-title.siila-menu-color {
  li {
    margin-bottom: 15px;
  }
}

.grocs-sub-title.siila-menu-color li {
  margin-bottom: 15px;
}

h2.feature-benefit.siila-menu-color li {
  margin-bottom: 15px;
}

.siila-menu-color {
  color: $menu-link-color !important;
}

.siila-value {
  color: $siila-index-value !important;
}

.siila-blue-color {
  color: $siila-blue-color !important;
}

.siila-blue-dark {
  color: $siila-blue-dark !important;
}

.siila-grey-dark {
  color: $menu-link-color !important;
}

.link-blue {
  color: $link-blue !important;
}

.siila-grey {
  color: $siila-gray-color !important;
}

.siila-dark {
  color: rgba(33, 33, 33, 1) !important;
}

.text-dark {
  color: rgba(33, 33, 33, 1) !important;
}

.mat-tooltip {
  background-color: $tooltip-background-color;
  color: $tooltip-text-color;
  font-size: $tooltip-font-size;
  padding: 8px 12px;
  line-height: 1.4;
}

.large-tooltip {
  padding: 12px 16px;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  max-height: 300px;
  max-width: 500px !important;
  overflow-y: auto;
  text-align: left;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
}

.large-tooltip::-webkit-scrollbar {
  width: 6px;
}

.large-tooltip::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

.large-tooltip .mat-tooltip-arrow {
  transform: scale(1.5);
}

.text-red {
  color: $siila-red;
}

.text-white {
  color: $white;
}

.all-upper {
  text-transform: uppercase;
}

.orange-link {
  &:hover {
    color: $siila-orange-color;
  }
}

.blue-link {
  color: $menu-link-color !important;
  margin: 0;

  &:hover {
    color: $menu-link-color !important;
  }
}

.bold {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

strong {
  font-weight: 600 !important;
}

a:hover {
  text-decoration: underline;
}

h3 {
  color: $siila-orange-color;
}

a {
  color: $siila-orange-color;
}

.service_worker_snack {
  // can be removed when https://github.com/angular/material2/pull/4045 will be merged
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.app-loading {
  margin-top: 20px;

  .logo {
    width: 150px;
    height: 150px;

    // this way asset gets processed by webpack
    background-image: url(../assets/img/SiiLA_SVG.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.pointer {
  cursor: pointer;
}

.leftRs {
  position: absolute;
  top: 50%;
  bottom: 50%;
  color: #04354f;
  background: transparent;
  border: unset;
  width: 55px;
  height: 55px;
  box-shadow: none;
  border-radius: 50%;
  z-index: 99;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include respond-to("small") {
    left: 0;
  }

  mat-icon {
    font-size: 24px;
    font-weight: 600;
  }

  &:focus {
    outline: none !important;
  }
}

.rightRs {
  position: absolute;
  top: 50%;
  bottom: 50%;
  color: #04354f;
  background: transparent;
  border: unset;
  width: 55px;
  height: 55px;
  box-shadow: none;
  border-radius: 50%;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  mat-icon {
    font-size: 24px;
    font-weight: 600;
  }

  &:focus {
    outline: none !important;
  }
}

.news-leftRs {
  @extend .leftRs;
  top: 40%;

  @include respond-to("small") {
    top: 145px;
    margin-left: -12px;
  }

  @include respond-to("xsmall") {
    top: 145px;
    margin-left: -18px;
  }
}

.news-rightLs {
  @extend .rightRs;
  top: 43%;
  right: 34px;

  @include respond-to("small") {
    top: 270px;
    left: 90%;
  }
}

.news-leftLs {
  @extend .leftRs;
  top: 43%;
  left: 50px;

  @include respond-to("small") {
    top: 270px;
    left: -5%;
  }
}

.news-rightRs {
  @extend .rightRs;
  top: 43%;

  @include respond-to("small") {
    top: 145px;
    margin-right: -12px;
  }

  @include respond-to("xsmall") {
    top: 145px;
    margin-right: -18px;
  }
}

lightbox gallery {
  position: relative;
}

/*video carousel @Overrides*/
.g-backdrop {
  background-color: rgba(0, 51, 76, 0.8) !important;
}

.siila-submit {
  background: orange;
  text-transform: uppercase;
  color: white;
}

.siila-blue-submit {
  background: #04354f;
  color: white;
  text-transform: uppercase;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
}

.center {
  justify-content: center;
}

.app-toolbar {
  @extend .sticky;
  top: 0;
  /* Sets the sticky toolbar to be on top */
  z-index: 999;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  /* For macOS/iOS Safari */
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
}

@media screen and (max-width: $break-large) {
  .mobile-width {
    width: 100% !important;
  }

  .mobile-padding {
    padding-bottom: 25px;
  }

  .just-center {
    color: #ff9900 !important;
  }

  .justify {
    display: unset !important;
    justify-content: unset !important;
  }

  .button-solid {
    --background: #043650;
  }

  .listing-btn-height:hover {
    background-color: #043650 !important;

    .purchase-block-8 {
      color: #ff9900;
    }
  }

  .list-addition {
    height: 170px !important;
  }

  .img-respons {
    display: block;
    width: 100% !important;
    height: calc(100vw * 3 / 4) !important;
    overflow: hidden;
  }

  .slide-height {
    img {
      height: calc(100vw * 3 / 4) !important;
      width: 100% !important;
      -o-object-fit: cover !important;
      object-fit: cover !important;
    }
  }

  .one-photo-static-map {
    img {
      height: calc(100vw * 3 / 4) !important;
      width: 100% !important;
      -o-object-fit: unset !important;
      object-fit: unset !important;
    }
  }

  .detail-flex {
    height: calc(100vw * 3 / 4) !important;
    width: 100% !important;
  }

  .our-partners {
    .partners-card-block {
      height: calc(100vw * 10 / 15) !important;
    }

    .partners-card-header {
      height: calc(100vw * 10 / 15) !important;
    }

    .partners-content {
      padding-top: 45px !important;
    }

    .card-alignment {
      height: unset !important;
    }

    .image-dots {
      top: 114% !important;
    }

    .hidden-content {
      height: 190px !important;
    }

    .img-block {
      width: 100% !important;
      height: calc(100vw * 10 / 15) !important;

      img {
        width: 100% !important;
        height: calc(100vw * 10 / 15) !important;
        object-fit: cover !important;
      }
    }

    .img-block-glass {
      height: calc(100vw * 10 / 15) !important;
      width: 100% !important;

      img {
        width: 100% !important;
        height: calc(100vw * 10 / 15) !important;
        object-fit: cover !important;
      }
    }
  }

  .card-block {
    width: 100% !important;
  }

  .card-alignment {
    width: 100% !important;
    border: unset !important;
    margin-left: unset !important;
    margin-right: unset !important;
    margin-top: unset !important;
  }

  featured-listing-card {
    width: 100% !important;

    mat-card {
      width: 100% !important;
      //height: unset !important;
      margin-left: unset !important;
      margin-top: unset !important;

      .region-bar-default {
        width: 100% !important;
      }

      .image-height-card {
        height: calc(100vw * 3 / 4) !important;
      }

      .image-details-height-card {
        height: calc(88.8vw * 3 / 4) !important;
      }

      img {
        height: calc(100vw * 3 / 4) !important;
        width: 100% !important;
        object-fit: cover !important;
      }

      .listing-company-logo {
        img {
          object-fit: contain !important;
          padding-top: 10px;
          width: 136px !important;
          height: 57px !important;
          padding-bottom: 10px;
        }
      }
    }
  }
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.remove-outline {
  outline: none !important;
}

.slick-slider {
  width: 100%;
  margin: auto;
}

.prev-slide.slick-disabled,
.next-slide.slick-disabled {
  pointer-events: none;
}

.prev-slide {
  left: 0px;
  z-index: 999;
  transform: scaleX(-1);
}

.next-slide {
  right: 0px;
  z-index: 999;
}

.nav-btn {
  height: 32px;
  position: absolute;
  width: 32px;
  cursor: pointer;
  top: 100px !important;
}

.next-slide::after {
  content: "";
  background: url(../assets/img/right-arrow-white.png) no-repeat;
  background-position: center;
  height: 32px;
  width: 32px;
  position: absolute;
  top: 50%;
  right: 0px;
  background-color: black;
  opacity: 0.2;
  transform: translateY(-50%);
}

.prev-slide::after {
  content: "";
  background: url(../assets/img/right-arrow-white.png) no-repeat;
  background-position: center;
  height: 32px;
  width: 32px;
  position: absolute;
  top: 50%;
  right: 0px;
  background-color: black;
  opacity: 0.2;
  transform: translateY(-50%);
}

// Spacing helpers

.no-margin {
  margin: 0;
}

.btn-decoration {
  text-decoration: none !important;
}

.title-gnrl {
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* letter-spacing: 2px; */
}

.border-card-market {
  margin-top: 34px !important;
}

.border-card {
  margin-top: 34px !important;
}

.demo-card {
  margin-top: 34px !important;
}

.pillar-card {
  margin-top: 34px !important;
}

.border-card-white {
  margin-top: 34px !important;
}

.border-card-add {
  margin-top: 34px !important;
}

.border-card-coverage {
  margin-top: 34px !important;
}

.card-interested {
  margin-top: 34px !important;
}

.border-white {
  margin-top: 34px !important;
}

.card-mapping {
  margin-top: 34px !important;
}

.border-card-white-spot {
  margin-top: 34px !important;
}

.border-academy-white {
  margin-top: 34px !important;
}

.home-card-white {
  margin-top: 34px !important;
}

.home-card-comercial {
  margin-top: 34px !important;
}

.home-card-spot {
  margin-top: 34px !important;
}

/* Center the carousel */
ngb-carousel {
  display: flex;
  justify-content: center;
}

/* Adjust the height and width of the carousel */
ngb-carousel img {
  height: 400px;
  width: 600px;
  object-fit: cover;
}

/* Style the arrow buttons */
.ngb-carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 24px;
  color: #ccc;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.ngb-carousel-control:hover {
  color: #000;
}

.ngb-carousel-control:focus,
.ngb-carousel-control:hover {
  background-color: transparent;
  border: none;
  outline: none;
  color: #007bff;
}

.container-sections {
  max-width: 1240px;
  margin: 0 auto;
  padding: 38px;
  margin-bottom: 9px;
}

.border-section {
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024,
    0 3px 14px 2px #0000001f;
  border-radius: 8px;

  @include respond-to("small") {
    box-shadow: none;
    border-radius: none;
  }
}

.title-press {
  color: #fff;
  font-weight: 700;
  font-size: 39px;
  line-height: 48px;
  letter-spacing: 0.5px;
  @include respond-to("small") {
    text-align: center;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.25px;
  }
}

.press-banner {
  margin-top: 20px;
  border-radius: 8px;
  border: solid 2px $siila-blue-color;
  display: flex;
  width: 100%;
  justify-content: center;
  color: $siila-blue-color;
  height: 60px;
  align-items: center;
  gap: 20px;

  .iconlabel-container {
    display: inline-flex;
    gap: 10px;
  }
}

.p-html p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 2px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.custom {
  background: rgba(0, 0, 0, 0.8);
}

.custom .mat-dialog-container {
  background: none;
}

.custom-modal-class-tenants {
  z-index: 1000 !important;
}

.custom-snackbar {
  height: 100px;
  max-height: 200px;
  width: 37vw;
  max-width: 600px !important;
  min-width: 350px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  position: fixed !important;
  top: 48% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;

  .mat-simple-snack-bar-content {
    font-size: medium;
  }
}

::ng-deep .mat-button-toggle.active-toggle {
  background-color: #f90;
  color: #331f00;
}

@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display"),
    url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;1,400&display=swap")
      format("truetype");
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
